import FlussonicEmbedVideoPlayer from "./flussonicEmbedVideoPlayer";
import FlussonicCustomVideoPlayer from "./flussonicCustomPlayer";

const getFlussonicPlayerClass = (streamConfiguration) => {
	const url = new URL(streamConfiguration.url);
	return (
		["ws:", "wss:"].includes(url.protocol)
			? FlussonicCustomVideoPlayer
			: FlussonicEmbedVideoPlayer
	)
}

export default getFlussonicPlayerClass;