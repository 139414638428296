import { GAME_STREAM_CONFIGURATION_TYPE } from "constants/game.constants";

import NanoVideoPlayer from "./nanoPlayer";
import WowzaVideoPlayer from "./wowzaPlayer";
import getFlussonicPlayerClass from "./flussonic";
import OvenPlayerVideoPlayer from "./ovenPlayerVideoPlayer";
import MP4VideoPlayer from "./mp4Player";

export const createPlayer = (type, streamConfiguration, options) => {
		let CurrentClass = MP4VideoPlayer;
		switch (type) {
			case GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS:
				CurrentClass = NanoVideoPlayer
				break;
			case GAME_STREAM_CONFIGURATION_TYPE.WOWZA:
				CurrentClass = WowzaVideoPlayer
				break;
			case GAME_STREAM_CONFIGURATION_TYPE.FLUSSONIC:
				CurrentClass = getFlussonicPlayerClass(streamConfiguration)
				break;
			case GAME_STREAM_CONFIGURATION_TYPE.OVEN_PLAYER:
				CurrentClass = OvenPlayerVideoPlayer
				break;
			default:
				break;
		}
		
		return new CurrentClass(streamConfiguration, options)
};
