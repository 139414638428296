import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Tooltip from "components/ui/tooltip";

import useMarketDisabledForBonus from "hooks/useMarketDisabledForBonus";
import useDocumentListener from "hooks/useDocumentListener";

import runMarketUtilsFunction from "utils/markets/run";
import { getAllCombinations } from "utils/common";

import { GAME_TYPE, GAME_STATUSES, GAME_ACTIVITY_STATE } from "constants/game.constants";
import { RACING_MARKETS_GROUPS } from "constants/market.constants";

import { doBet, setForecast, clearForecast } from "store/actions/betslip/betslip.actions";

import eventType from "types/event.type";
import betType from "types/bet.type";
import sessionType from "types/session.type";

const GROUPS = {
	[2]: [RACING_MARKETS_GROUPS.PAIR, RACING_MARKETS_GROUPS.PAIR_ANY_ORDER],
	[3]: [RACING_MARKETS_GROUPS.TRIPLE, RACING_MARKETS_GROUPS.TRIPLE_ANY_ORDER]
}

/** Race Markets list component */
const RaceMarkets = ({ eventInfo, markets, currentGameType, doBet, setForecast, clearForecast, bets, forecast, session }) => {
	const { t } = useTranslation();

	const [selectedForecast, setSelectedForecast] = useState([]);

	const { isSingleMarketDisable } = useMarketDisabledForBonus();

	const game = session?.games?.find((g) => g.type === currentGameType);
	const isForecastAllowed = markets.some((m) => m.group === RACING_MARKETS_GROUPS.PAIR && m.odds && m.odds.length > 0 && m.odds[0].length > 0);
	const isTricastAllowed = markets.some((m) => m.group === RACING_MARKETS_GROUPS.TRIPLE && m.odds && m.odds.length > 0 && m.odds[0].length > 0);

	const isReverseForecastEnabled = !!game?.rtPs.find(rtP => rtP.name === RACING_MARKETS_GROUPS.PAIR_ANY_ORDER)?.enabled;
	const isReverseTricastEnabled = !!game?.rtPs.find(rtP => rtP.name === RACING_MARKETS_GROUPS.TRIPLE_ANY_ORDER)?.enabled;

	// Unchangeable referance of ESC button click handler
	useDocumentListener("keyup", (event) => {
		if (event.key === "Escape") {
			setSelectedForecast([]);
			clearForecast();
		}
	});

	/** Empty forecast selection, when redux forecast selection is null */
	useEffect(() => {
		if (!forecast && selectedForecast.some((forecast) => [0, 1, 2].includes(forecast.value))) {
			setSelectedForecast([]);
		}
	}, [forecast]);

	/** Update forecast in redux, on forecast selection change */
	useEffect(() => {
		const isValid = selectedForecast.some((forecast) => [0, 1, 2].includes(forecast.value));

		if (!isValid) {
			clearForecast();
			return;
		}

		const anyOrder = selectedForecast.some((s) => s.value === 0);
		const participants = [...selectedForecast].sort((a, b) => (a.value > b.value ? 1 : -1)).map((p) => p.participant);

		let obj = null;
		if ((participants.length === 2 && isReverseForecastEnabled) || participants.length === 3) {
			obj = markets.find((m) => m.group === GROUPS[participants.length][anyOrder ? 1 : 0] && m.odds && m.odds.length > 0 && m.odds[0].length > 0);
		}

		if (!obj) {
			clearForecast();
			return;
		}

		const oddName = participants.sort((a, b) => a > b ? 1 : -1).join("-");
		const odd = obj.odds[0].find((b) => b.outcome === oddName);
		setForecast({
			odd: odd,
			group: runMarketUtilsFunction("makeGroupTitle", [{ group: obj.group, gameType: currentGameType }], currentGameType),
			eventInfo: eventInfo,
			gameType: currentGameType
		});
	}, [selectedForecast]);

	/** Function to check if bet is already in betslip bets
	 * @function
	 * @param {string} id
	 * @returns {boolean}
	 * @memberOf RaceMarkets
	 */
	const isOddSelected = (id) => {
		return bets.some((b) => b.oddId === id && b.eventId === eventInfo.id);
	};

	/** Get Participant odd by index
	 * @function
	 * @param {string} market - Win/Place/Show
	 * @param {number} index - participant index
	 * @returns {object}
	 * @memberOf RaceMarkets
	 */
	const getParticipantOdd = (market, index) => {
		const oddMarkets = markets.find((m) => m.group === market);
		const odds = oddMarkets?.odds ?? [];
		const odd = odds && odds[0] ? odds[0][index] : null;
		return odd;
	};

	/** Function which fires on market click
	 * @function
	 * @description adds bet to betslip bets list
	 * @param {object} odd - odd info
	 * @param {object} market - market info
	 * @memberOf RaceMarkets
	 */
	const handleOddClick = (odd, groupName) => {
		if (isOddDisabled(odd)) {
			return;
		}

		if (isSingleMarketDisable(odd.factor)) {
			return;
		}

		const group = runMarketUtilsFunction("makeGroupTitle", [{ group: groupName, argument: null, gameData: eventInfo?.gameData ?? {}, gameType: currentGameType }], currentGameType);
		doBet(odd, group, eventInfo);
	};

	/** Function to check if odd is disabled
	 * @function
	 * @param {object} id
	 * @returns {boolean}
	 * @memberOf RaceMarkets
	 */
	const isOddDisabled = (odd) => {
		if (!odd) return true;
		return (
			game?.state === GAME_ACTIVITY_STATE.CLOSE_FOR_BETTING ||
			[GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.STARTED].includes(eventInfo?.status) ||
			!odd.factor ||
			odd.factor < 1.01
		);
	};

	/** Function detects if the cell should be disabled
	 * @function
	 * @param {number} - cell number
	 * @param {number} - participant number
	 * @returns {boolean}
	 * @memberOf RaceMarkets
	 */
	const isForecastDisabled = (cell, participant) => {
		if (isForecastSelected(cell, participant)) {
			return false;
		}

		if (
			game?.state === GAME_ACTIVITY_STATE.CLOSE_FOR_BETTING ||
			[GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.STARTED].includes(eventInfo?.status)
		) {
			return true;
		}

		if (eventInfo.status !== GAME_STATUSES.NEW && eventInfo.status !== GAME_STATUSES.PREAMBLE_STARTED) return false;

		if (selectedForecast.length > 0) {
			const collectedData = selectedForecast.reduce(
				(acc, currentCell) => {
					if (currentCell.participant === participant) {
						acc.isNotTheSameParticipant = false;
					}
					if (currentCell.value === 0) {
						acc.selectedAnyCount++;
					} else if (!acc.isSelectedFirstCell) {
						acc.isSelectedFirstCell = true;
					}

					return acc;
				},
				{ selectedAnyCount: 0, isSelectedFirstCell: false, isNotTheSameParticipant: true }
			);

			if (collectedData.isSelectedFirstCell && cell === 0) {
				return true;
			} else if ((collectedData.selectedAnyCount === 3 || (!isReverseTricastEnabled && collectedData.selectedAnyCount === 2)) && cell === 0 && collectedData.isNotTheSameParticipant) {
				return true;
			} else if (collectedData.selectedAnyCount >= 1 && cell !== 0) {
				return true;
			}
		}

		let disabled = false;

		switch (cell) {
			case 0:
				disabled = (!isForecastAllowed && !isTricastAllowed) || selectedForecast.some((s) => s.value !== 0) || selectedForecast.length === 3;
				break;
			case 1:
				disabled = (!isForecastAllowed && !isTricastAllowed) || selectedForecast.some((s) => s.value === 0 || s.participant === participant);
				break;
			case 2:
				disabled = (!isForecastAllowed && !isTricastAllowed) || selectedForecast.some((s) => s.value === 0 || s.participant === participant) || !selectedForecast.some((s) => s.value === 1);
				break;
			case 3:
				disabled = !isTricastAllowed || selectedForecast.some((s) => s.value === 0 || s.participant === participant) || !selectedForecast.some((s) => s.value === 1) || !selectedForecast.some((s) => s.value === 2);
				break;
		}

		return disabled;
	};

	/** Function detects if the cell should be selected
	 * @function
	 * @param {number} - cell number
	 * @param {number} - participant number
	 * @returns {boolean}
	 * @memberOf RaceMarkets
	 */
	const isForecastSelected = (cell, participant) => {
		return selectedForecast.some((s) => s.value === cell && s.participant === participant);
	};

	/** Fires on cell click
	 * @function
	 * @param {number} - cell number
	 * @param {number} - participant number
	 * @memberOf RaceMarkets
	 */
	const handleForecastClick = (cell, participant) => {
		if (isForecastDisabled(cell, participant)) {
			return;
		}

		if (eventInfo.status !== GAME_STATUSES.NEW && eventInfo.status !== GAME_STATUSES.PREAMBLE_STARTED) {
			return;
		}

		if (selectedForecast.some((s) => s.value === cell && s.participant === participant)) {
			setSelectedForecast(selectedForecast.filter((s) => s.value !== cell || s.participant !== participant));
		} else {
			setSelectedForecast([...selectedForecast.filter((s) => s.value !== cell || s.value === 0), { value: cell, participant: participant }]);
		}
	};

	useEffect(() => {
		if ([GAME_STATUSES.NEW, GAME_STATUSES.PREAMBLE_STARTED].includes(eventInfo.status)) {
			return;
		}
		setSelectedForecast([]);
	}, [eventInfo, setSelectedForecast]);

	const reverseMarketTitle = (isReverseForecastEnabled && !isReverseTricastEnabled) ? 'reverseForecast' :
			(isReverseTricastEnabled && !isReverseForecastEnabled) ? 'reverseTricast' : 'reverseForecastTracastSection';

	return (
		<div className="vs--markets-table">
			<div className="vs--markets-table-head">
				<div className="vs--markets-table-row-head vs--flex vs--align-center vs--justify-end">
					<div className="vs--markets-table-cell vs--flex vs--justify-around">
						<div className="vs--markets-table-cell vs--flex vs--justify-around">
							<div className="vs--markets-table-cell vs--markets-table-cell-factor vs--flex vs--flex-equal vs--align-center vs--justify-center vs--markets-border">
								<span className="vs--title vs--font-exstrasmall vs--font-regular" title={t(`markets.${currentGameType}.winSection`)}>
									{t(`markets.${currentGameType}.winSection`)}
								</span>
							</div>
							<div className="vs--markets-table-cell vs--markets-table-cell-factor vs--flex vs--align-center vs--justify-center vs--markets-border">
								<span className="vs--title vs--font-exstrasmall vs--font-regular" title={t(`markets.${currentGameType}.placeSection`)}>
									{t(`markets.${currentGameType}.placeSection`)}
								</span>
							</div>

							<div className="vs--markets-table-cell vs--markets-table-cell-factor vs--flex vs--align-center vs--justify-center vs--markets-border">
								<span className="vs--title vs--font-exstrasmall vs--font-regular" title={t(`markets.${currentGameType}.showSection`)}>
									{t(`markets.${currentGameType}.showSection`)}
								</span>
							</div>
						</div>

						<div className="vs--markets-table-cell vs--markets-table-cell-forecast vs--flex vs--align-center vs--justify-center vs--markets-border-left">
							<span className="vs--title vs--font-exstrasmall vs--font-regular" title={t(`markets.${currentGameType}.forecastTracastSection`)}>
								{t(`markets.${currentGameType}.forecastTracastSection`)}
							</span>
						</div>

						{(isReverseForecastEnabled || isReverseTricastEnabled) && (
							<div className="vs--markets-table-cell vs--text-center vs--markets-table-cell-reverse-forecast vs--flex vs--align-center vs--justify-center vs--markets-border-left">
								<span className="vs--title vs--font-exstrasmall vs--font-regular" title={t(`markets.${currentGameType}.${reverseMarketTitle}`)}>
									{t(`markets.${currentGameType}.${reverseMarketTitle}`)}
								</span>
							</div>
						)}
					</div>
				</div>
				<div className="vs--markets-table-row-head-sub vs--flex vs--align-center vs--justify-end vs--pt-4 vs--pb-4">
					<div className="vs--markets-table-cell vs--flex">
						<div className="vs--markets-table-cell vs--flex vs--flex-equal vs--justify-around">
							<div className="vs--markets-table-cell vs--markets-table-cell-factor vs--flex vs--flex-equal vs--align-center vs--justify-center vs--markets-border-dark">
								<span className="vs--title vs--font-smallest vs--font-medium vs--pt-4 vs--pb-4" title={t(`markets.${currentGameType}.Winner`)}>
									{t(`markets.${currentGameType}.Winner`)}
								</span>
							</div>
							<div className="vs--markets-table-cell vs--markets-table-cell-factor vs--flex vs--flex-equal vs--align-center vs--justify-center vs--markets-border-dark">
								<span className="vs--title vs--font-smallest vs--font-medium vs--pt-4 vs--pb-4" title={t(`markets.${currentGameType}.Place`)}>
									{t(`markets.${currentGameType}.Place`)}
								</span>
							</div>
							<div className="vs--markets-table-cell vs--markets-table-cell-factor vs--flex vs--flex-equal vs--align-center vs--justify-center vs--markets-border-dark">
								<span className="vs--title vs--font-smallest vs--font-medium vs--pt-4 vs--pb-4" title={t(`markets.${currentGameType}.Show`)}>
									{t(`markets.${currentGameType}.Show`)}
								</span>
							</div>
						</div>
						<div className="vs--markets-table-cell vs--markets-table-cell-forecast vs--flex vs--flex-equal vs--justify-around vs--markets-border-left-dark">
							<div className="vs--markets-table-cell vs--markets-table-cell-index vs--flex vs--align-center vs--justify-center">
								<span className="vs--title vs--font-smallest  vs--font-medium vs--pt-4 vs--pb-4" title={t(`markets.${currentGameType}.1st`)}>
									{t(`markets.${currentGameType}.1st`)}
								</span>
							</div>
							<div className="vs--markets-table-cell vs--markets-table-cell-index vs--flex vs--align-center vs--justify-center">
								<span className="vs--title vs--font-smallest  vs--font-medium vs--pt-4 vs--pb-4" title={t(`markets.${currentGameType}.2nd`)}>
									{t(`markets.${currentGameType}.2nd`)}
								</span>
							</div>
							<div className="vs--markets-table-cell vs--markets-table-cell-index vs--flex vs--align-center vs--justify-center">
								<span className="vs--title vs--font-smallest  vs--font-medium vs--pt-4 vs--pb-4" title={t(`markets.${currentGameType}.3rd`)}>
									{t(`markets.${currentGameType}.3rd`)}
								</span>
							</div>
						</div>
						{(isReverseForecastEnabled || isReverseTricastEnabled) && (
							<div className="vs--markets-table-cell vs--markets-table-cell-reverse-forecast vs--flex vs--flex-equal vs--justify-around vs--markets-border-left-dark">
								<div className="vs--markets-table-cell vs--markets-table-cell-index vs--flex vs--align-center vs--justify-center">
									<span className="vs--title vs--font-smallest  vs--font-medium vs--pt-4 vs--pb-4" title={t(`markets.${currentGameType}.1st`)}>
										{t(`markets.${currentGameType}.any`)}
									</span>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="vs--markets-table-body">
				{(eventInfo?.gameData.participants ?? []).map((participant, index) => (
					<div className="vs--markets-table-row vs--flex vs--align-center vs--justify-end" key={index}>
						<div className="vs--markets-table-cell vs--flex vs--align-center vs--justify-start">
							<div className="vs--markets-table-cell vs--flex vs--align-center vs--justify-center vs--markets-table-cell-index vs--pl-24 vs--pr-12">
								<span className={"vs--place vs--font-bold vs--place-" + (index + 1)}>{index + 1}</span>
							</div>

							<div className="vs--markets-table-cell vs--flex vs--align-center vs--justify-start vs--markets-table-cell-name vs--flex-equal">
								<span className="vs--title vs--font-exstrasmall vs--font-medium">
									{[GAME_TYPE.HORSE_RACING, GAME_TYPE.HORSE_STEEPLECHASING].includes(currentGameType) ? `${participant.name}/${participant.jockeyName}` : currentGameType === GAME_TYPE.GREYHOUNDS_RACE ? `${participant.name}/${participant.trainer}` : null}
								</span>
							</div>
						</div>

						<div className="vs--markets-table-cell vs--flex vs--justify-around vs--ml-auto">
							<div className="vs--markets-table-cell-winner vs--flex vs--flex-equal vs--justify-around vs--racing-tooltip">
								{["Winner", "Place", "Show"].map((item, i) => {
									const participantOdd = getParticipantOdd(item, index) ?? {};

									return (
										<div className={"vs--markets-table-cell vs--pl-16 vs--pr-16 vs--flex vs--flex-equal vs--justify-center vs--align-center vs--markets-border"} key={item}>
											<Tooltip
												overlayClassName={"vs--racing-tool" + ` vs--racing-tool-${item}`}
												title={isSingleMarketDisable(participantOdd.factor) ? <span className="vs--markets-list-item-content-odd-item-bonus-title">{t("cashier.unavailableForFreeBet")}</span> : null}
												placement="bottom"
											>
												<div
													className={
														"vs--flex vs--align-center vs--justify-center vs--markets-table-factor" +
														(isOddSelected(participantOdd.id) ? " vs--markets-table-factor-selected" : "") +
														(isOddDisabled(participantOdd) || isSingleMarketDisable(participantOdd.factor) ? " vs--markets-table-factor-disabled" : "")
													}
													onClick={() => handleOddClick(participantOdd, item)}
												>
													{!isOddDisabled(participantOdd) ? <b className="vs--title vs--font-small vs--font-regular">{participantOdd.factor}</b> : <i className="ic_lock vs--font-bigest" />}
												</div>
											</Tooltip>
										</div>
									);
								})}
							</div>
							<div className="vs--markets-table-cell vs--flex-equal vs--flex vs--align-center vs--justify-center vs--markets-border-left">
								<div className="vs--markets-table-cell vs--markets-table-cell-forecast vs--flex-equal vs--flex vs--align-center vs--pl-16 vs--pr-16">
									{[1, 2, 3].map((i) => (
										<div
											className={
												"vs--markets-table-factor vs--markets-table-factor-forecast vs--flex vs--justify-center vs--align-center" +
												(isForecastSelected(i, index + 1) ? " vs--markets-table-factor-forecast-selected" : "") +
												(isForecastDisabled(i, index + 1) ? " vs--markets-table-factor-forecast-disabled" : "")
											}
											key={i}
											onClick={() => handleForecastClick(i, index + 1)}
										>
											{isForecastDisabled(i, index + 1) ? (
												<i className="ic_lock" />
											) : isForecastSelected(i, index + 1) ? (
												<b className="vs--title vs--font-small vs--font-regular">{t(`markets.${currentGameType}.${i === 1 ? "1st" : i === 2 ? "2nd" : i === 3 ? "3rd" : "any"}`)}</b>
											) : (
												<i className="ic_done" />
											)}
										</div>
									))}
								</div>
							</div>
							{(isReverseForecastEnabled || isReverseTricastEnabled) && (
								<div className="vs--markets-table-cell vs--flex-equal vs--flex vs--align-center vs--justify-center vs--markets-border-left">
									<div className="vs--markets-table-cell vs--markets-table-cell-reverse-forecast vs--flex-equal vs--flex vs--align-center vs--justify-around">
										<div
											className={
												"vs--markets-table-factor vs--markets-table-factor-forecast vs--flex vs--justify-center vs--align-center" +
												(isForecastSelected(0, index + 1) ? " vs--markets-table-factor-forecast-selected" : "") +
												(isForecastDisabled(0, index + 1) ? " vs--markets-table-factor-forecast-disabled" : "")
											}
											onClick={() => handleForecastClick(0, index + 1)}
										>
											{isForecastDisabled(0, index + 1) ? <i className="ic_lock" /> : isForecastSelected(0, index + 1) ? <b className="vs--title vs--font-small vs--font-regular">{t(`markets.${currentGameType}.any`)}</b> : <i className="ic_done" />}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

/** RaceMarkets propTypes
 * PropTypes
 */
RaceMarkets.propTypes = {
	/** Current event info */
	eventInfo: eventType,
	/** Array of markets to show */
	markets: PropTypes.array,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux action to add bet into betslip bets */
	doBet: PropTypes.func,
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux action to set forecast */
	setForecast: PropTypes.func,
	/** Redux action to clear forecast */
	clearForecast: PropTypes.func,
	/** Redux state property, Forecast/Tricast bet selection */
	forecast: PropTypes.object,
	/** Redux state property, current session */
	session: sessionType
};

const mapStateToProps = (state) => {
	return {
		currentGameType: state.game.currentGameType,
		bets: state.betslip.bets,
		forecast: state.betslip.forecast,
		session: state.auth.session
	};
};

const mapDispatchToProps = (dispatch) => ({
	doBet: (market, group, eventInfo) => {
		dispatch(doBet(market, group, eventInfo));
	},
	setForecast: (forecast) => {
		dispatch(setForecast(forecast));
	},
	clearForecast: () => {
		dispatch(clearForecast());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(RaceMarkets);
